<script lang="ts">
  export let label = '';
  export let name = '';
  export let value: string | number = '';
  export let placeholder = '';
  export let styleClass = '';
  export let error = '';
  export let type = 'text';
  export let rows = 3; // Default number of rows for textarea
  export let options: { value: any; text: string }[] = [];
  export let min: number | string = undefined;
  export let max: number | string = undefined;
  export let disabled = false;
  export let testId = '';
</script>

<div class={styleClass}>
  {#if label}
    <div class="mb-1">
      <label class="text-sm w-full font-medium text-gray-900" for={name}>
        {label}
      </label>
    </div>
  {/if}
  {#if type === 'number'}
    <input
      data-cy={testId}
      id={name}
      min={min}
      max={max}
      on:change
      class={`w-full focus:outline-none rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600
      ${error ? 'ring-red-500' : ''}
    `}
      {...{ type }}
      name={name}
      placeholder={placeholder}
      bind:value={value}
    />
  {/if}
  {#if type === 'select'}
    <select
      data-cy={testId}
      id={name}
      on:change
      class={`w-full focus:outline-none hover:cursor-pointer rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600
        ${error ? 'ring-red-500' : ''}
      `}
      name={name}
      placeholder={placeholder}
      bind:value={value}
    >
      {#each options as option}
        <option value={option.value}>{option.text}</option>
      {/each}
    </select>
  {/if}
  {#if type === 'textarea'}
    <textarea
      data-cy={testId}
      id={name}
      rows={rows}
      on:change
      class={`w-full focus:outline-none rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600
        ${error ? 'ring-red-500' : ''}
      `}
      name={name}
      placeholder={placeholder}
      bind:value={value}
    />
  {:else if type === 'checkbox'}
    <div class="relative flex w-full flex-row items-center justify-start">
      <input
        id={name}
        data-cy={testId}
        disabled={disabled}
        on:change
        on:input
        class="me-3 rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-400"
        {...{ type }}
        name={name}
        max={max}
        min={min}
        placeholder={placeholder}
        bind:value={value}
      />
      <slot />
    </div>
  {/if}

  {#if type !== 'select' && type !== 'textarea' && type !== 'number' && type !== 'checkbox'}
    <input
      data-cy={testId}
      id={name}
      min={min}
      max={max}
      on:change
      class={`w-full focus:outline-none rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600
        ${error ? 'ring-red-500' : ''}
      `}
      {...{ type }}
      name={name}
      placeholder={placeholder}
      bind:value={value}
    />
  {/if}
  {#if error}
    <span class="text-xs text-red-600 font-medium w-full">
      {#if Array.isArray(error)}
        {error[0]}
      {:else}
        {error}
      {/if}
    </span>
  {/if}
</div>
