import * as yup from 'yup';

export const priceSchema = yup.object({
  smallCompanySeats: yup
    .number()
    .required('Seats are required')
    .test(
      'seats-order',
      'Must be less than medium company Seats',
      function (value) {
        return value < this.parent.mediumCompanySeats;
      },
    ),
  mediumCompanySeats: yup
    .number()
    .required('Seats are required')
    .test(
      'seats-order',
      'Must be between small and large company Seats',
      function (value) {
        return (
          value > this.parent.smallCompanySeats &&
          value < this.parent.largeCompanySeats
        );
      },
    ),
  largeCompanySeats: yup
    .number()
    .required('Seats are required')
    .test(
      'seats-order',
      'Must be greater than medium company Seats',
      function (value) {
        return value > this.parent.mediumCompanySeats;
      },
    ),

  starterSmallCompanyPrice: yup
    .number()
    .required('Price is required')
    .test(
      'price-order',
      'Must be less than medium company price',
      function (value) {
        return value < this.parent.starterMediumCompanyPrice;
      },
    ),
  starterMediumCompanyPrice: yup
    .number()
    .required('Price is required')
    .test(
      'price-order',
      'Must be between small and large company price',
      function (value) {
        return (
          value > this.parent.starterSmallCompanyPrice &&
          value < this.parent.starterLargeCompanyPrice
        );
      },
    ),
  starterLargeCompanyPrice: yup
    .number()
    .required('Price is required')
    .test(
      'price-order',
      'Must be greater than medium company price',
      function (value) {
        return value > this.parent.starterMediumCompanyPrice;
      },
    ),

  proSmallCompanyPrice: yup
    .number()
    .required('Price is required')
    .test(
      'price-order',
      'Must be less than medium company price',
      function (value) {
        return value < this.parent.proMediumCompanyPrice;
      },
    ),
  proMediumCompanyPrice: yup
    .number()
    .required('Price is required')
    .test(
      'price-order',
      'Must be between small and large company price',
      function (value) {
        return (
          value > this.parent.proSmallCompanyPrice &&
          value < this.parent.proLargeCompanyPrice
        );
      },
    ),
  proLargeCompanyPrice: yup
    .number()
    .required('Price is required')
    .test(
      'price-order',
      'Must be greater than medium company price',
      function (value) {
        return value > this.parent.proMediumCompanyPrice;
      },
    ),
});
