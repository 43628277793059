export interface ISubmissionsResponse {
  submissions: ISubmission[];
  count: number;
}

export interface ISubmission {
  id: string;
  orgName: string;
  name: string;
  lastName: string;
  email: string;
  status: SubmissionStatusEnum;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
}

export enum SubmissionStatusEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}
