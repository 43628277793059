<script lang="ts">
  import { validator } from '@felte/validator-yup';
  import { createForm } from 'felte';
  import { onMount } from 'svelte';

  import { SubscriptionPlan } from '../../interfaces/IExperience';
  import type { ISubscription } from '../../interfaces/ISubscription';
  import Button from '../../lib/components/button/Button.svelte';
  import Input from '../../lib/components/input/Input.svelte';
  import { ToastNotification } from '../../lib/services/toast';
  import {
    getSubscriptionPlans,
    updatePlan,
  } from '../../lib/services/together/together.api';
  import { priceSchema } from './price.validation.schema';

  let plans: ISubscription[] = [];

  const initialValues = {
    smallCompanySeats: 0,
    mediumCompanySeats: 0,
    largeCompanySeats: 0,
    starterSmallCompanyPrice: 0,
    starterMediumCompanyPrice: 0,
    starterLargeCompanyPrice: 0,
    proSmallCompanyPrice: 0,
    proMediumCompanyPrice: 0,
    proLargeCompanyPrice: 0,
  };

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      await Promise.all([
        updatePlan({
          ...plans[0],
          plan: SubscriptionPlan.STARTER,
          priceId: plans[0]?.priceId ?? 'starter_price_id',
          smallCompanySeats: values.smallCompanySeats,
          mediumCompanySeats: values.mediumCompanySeats,
          largeCompanySeats: values.largeCompanySeats,
          smallCompanyPrice: values.starterSmallCompanyPrice,
          mediumCompanyPrice: values.starterMediumCompanyPrice,
          largeCompanyPrice: values.starterLargeCompanyPrice,
        }),
        updatePlan({
          ...plans[1],
          plan: SubscriptionPlan.PRO,
          priceId: plans[1]?.priceId ?? 'pro_price_id',
          smallCompanySeats: values.smallCompanySeats,
          mediumCompanySeats: values.mediumCompanySeats,
          largeCompanySeats: values.largeCompanySeats,
          smallCompanyPrice: values.proSmallCompanyPrice,
          mediumCompanyPrice: values.proMediumCompanyPrice,
          largeCompanyPrice: values.proLargeCompanyPrice,
        }),
      ]);
      ToastNotification.success('Subscription plans updated successfully');
    } catch (error) {
      ToastNotification.error(error?.response?.data?.message ?? error.message);
    }
  };

  const { form, errors, isSubmitting, setFields } = createForm({
    initialValues,
    extend: [validator({ schema: priceSchema })],
    onSubmit: (values) => handleSubmit(values),
  });

  onMount(() => {
    getSubscriptionPlans()
      .then((result) => {
        plans = result;
        setFields({
          smallCompanySeats: plans[0]?.smallCompanySeats ?? 0,
          mediumCompanySeats: plans[0]?.mediumCompanySeats ?? 0,
          largeCompanySeats: plans[0]?.largeCompanySeats ?? 0,
          starterSmallCompanyPrice: plans[0]?.smallCompanyPrice ?? 0,
          starterMediumCompanyPrice: plans[0]?.mediumCompanyPrice ?? 0,
          starterLargeCompanyPrice: plans[0]?.largeCompanyPrice ?? 0,
          proSmallCompanyPrice: plans[1]?.smallCompanyPrice ?? 0,
          proMediumCompanyPrice: plans[1]?.mediumCompanyPrice ?? 0,
          proLargeCompanyPrice: plans[1]?.largeCompanyPrice ?? 0,
        });
      })
      .catch((error) => {
        console.error(error);
        ToastNotification.error('Could not fetch subscription plans');
      });
  });
</script>

<div class="flex flex-col gap-4 px-4 md:px-10 mx-auto w-full min-h-screen p-4">
  <div
    class="flex items-center justify-center bg-white border border-gray-200 rounded-lg shadow-sm p-2"
  >
    <h3 class="text-2xl font-bold">Update Subscription Plans</h3>
  </div>

  <form
    class="grid grid-cols-3 gap-6 bg-white border border-gray-200 p-6 rounded-lg"
    use:form
  >
    <div>
      <h3 class="text-xl font-bold text-left pb-4">Company Seats</h3>
      <Input
        type="number"
        min="0"
        name="smallCompanySeats"
        label="Small"
        error={$errors.smallCompanySeats?.[0] ?? ''}
      />
      <Input
        type="number"
        min="0"
        name="mediumCompanySeats"
        label="Medium"
        error={$errors.mediumCompanySeats?.[0] ?? ''}
      />
      <Input
        type="number"
        min="0"
        name="largeCompanySeats"
        label="Large"
        error={$errors.largeCompanySeats?.[0] ?? ''}
      />
    </div>

    <div>
      <h3 class="text-xl font-bold text-left pb-4">Starter Plan Pricing</h3>
      <Input
        type="number"
        min="0"
        name="starterSmallCompanyPrice"
        label="Small"
        error={$errors.starterSmallCompanyPrice?.[0] ?? ''}
      />
      <Input
        type="number"
        min="0"
        name="starterMediumCompanyPrice"
        label="Medium"
        error={$errors.starterMediumCompanyPrice?.[0] ?? ''}
      />
      <Input
        type="number"
        min="0"
        name="starterLargeCompanyPrice"
        label="Large"
        error={$errors.starterLargeCompanyPrice?.[0] ?? ''}
      />
    </div>

    <div>
      <h3 class="text-xl font-bold text-left pb-4">Pro Plan Pricing</h3>
      <Input
        type="number"
        min="0"
        name="proSmallCompanyPrice"
        label="Small"
        error={$errors.proSmallCompanyPrice?.[0] ?? ''}
      />
      <Input
        type="number"
        min="0"
        name="proMediumCompanyPrice"
        label="Medium"
        error={$errors.proMediumCompanyPrice?.[0] ?? ''}
      />
      <Input
        type="number"
        min="0"
        name="proLargeCompanyPrice"
        label="Large"
        error={$errors.proLargeCompanyPrice?.[0] ?? ''}
      />
    </div>

    <Button
      type="submit"
      disabled={$isSubmitting}
      isLoading={$isSubmitting}
      text="Update Plans"
    />
  </form>
</div>
